<script>
import Layout from "@/router/layouts/main";
import PageHeader from '@/components/page-header'
import appConfig from "@/app.config";
import StoreService from '@/services/store'
import { VMoney } from 'v-money'

export default {
  page: {
    title: "Configurações",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  directives: { money: VMoney },
  data() {
    return {
      title: "Configurações",
      bankAccount: {},
      canEdit: true,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
    };
  },
  mounted() {
    this.load()
  },
  methods: {
    load(showLoading = true) {
      let loader;
      if (showLoading) {
        loader = this.$loading.show();
      }

      StoreService.getStoreData().then(res => {
        this.bankAccount = res.data.bankAccount || {};

        if (this.bankAccount.bank && this.bankAccount.agency && this.bankAccount.account) {
          this.canEdit = false;
        }

        if (showLoading) {
          loader.hide();
        }
      }).catch(() => {
        if (showLoading) {
          loader.hide();
        }
      })
    },
    save() {
      if (!this.bankAccount.bank || !this.bankAccount.agency || !this.bankAccount.account) {
        return this.$toast.warning('Preencha todos os campos corretamente');
      }

      let loader = this.$loading.show();
      const data = {
        bankAccount: this.bankAccount,
      }

      StoreService.updateStore(data).then(() => {
        this.$toast.open('Dados bancários atualizados com sucesso');
        this.load(false);

        loader.hide();
      }).catch(() => {
        this.$toast.error('Ocorreu um erro ao atualizar os dados');

        loader.hide();
      })
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />

    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Dados Bancários</h4>
            <p class="card-title-desc">Informe a conta para recebimento dos seus pedidos</p>
            <div class="row mb-2 mt-0">
              <div class="col-12">
                <b-alert show variant="warning">Importante: Você poderá definir os dados bancários apenas uma vez.</b-alert>
              </div>
            </div>

            <form>
              <div class="row">
                <div class="col-sm-3 col-lg-3">
                  <div class="form-group">
                    <label for="bank">Banco</label>
                    <input id="bank" name="bank" type="tel" class="form-control" maxlength="3" v-model="bankAccount.bank" :disabled="!canEdit" />
                  </div>
                </div>

                <div class="col-sm-3 col-lg-3">
                  <div class="form-group">
                    <label for="agency">Agência</label>
                    <input id="agency" name="agency" type="text" class="form-control" maxlength="4" v-model="bankAccount.agency" :disabled="!canEdit" />
                  </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                  <div class="form-group">
                    <label for="account">Conta (com dígito)</label>
                    <input id="account" name="account" type="text" class="form-control" v-model="bankAccount.account" :disabled="!canEdit" />
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-12">
                  <div class="text-sm-right">
                    <button type="button" class="btn btn-success btn-rounded" @click="save" :disabled="!canEdit">Salvar Dados</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style lang="scss">
  input[type="file"] {
    display: none;
  }
</style>