import api from '@/services/api'

export default {
  getAllStores(page) {
    return api.get('/stores?page=' + page)
  },

  getStoreData() {
    return api.get('/stores/data')
  },

  createStore(data) {
    return api.post('/stores', data)
  },

  registerPartner(data) {
    return api.post('/stores/partner', data)
  },

  updateStore(data) {
    return api.put('/stores', data)
  },

  removeStore(id) {
    return api.delete('/stores/' + id)
  },

  activateStore(id) {
    return api.put(`/stores/${id}/activate`)
  },

  deactivateStore(id) {
    return api.put(`/stores/${id}/deactivate`)
  },

  approveStore(id) {
    return api.put(`/stores/${id}/approve`)
  },

  uploadImage(formData) {
    return api.put('/stores/image', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  getProducts(page, params) {
    let queryParams = `?page=${page}&term=${params.term}`;

    if (params.brands) {
      queryParams += `&brands=${params.brands.join(';')}`
    }

    if (params.categories) {
      queryParams += `&categories=${params.categories.join(';')}`
    }

    return api.get(`/stores/products${queryParams}`)
  },

  getStoreAdminProducts(page, params) {
    let queryParams = `?page=${page}&term=${params.term}&store=${params.store}`;

    if (params.brands) {
      queryParams += `&brands=${params.brands.join(';')}`
    }

    if (params.categories) {
      queryParams += `&categories=${params.categories.join(';')}`
    }

    return api.get(`/stores/admin/products${queryParams}`)
  },
}